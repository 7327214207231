<template>
  <c-button
    variant="ghost"
    m="0"
    p="0"
    min-w="auto"
    h="auto"
    w="auto"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </c-button>
</template>

<script>
export default {
  name: 'FlatButton'
}
</script>
